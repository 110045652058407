/* Scoped Styles */
.custom-page {
    --main-green: #79dd09;
    --main-green-rgb-015: rgba(121, 221, 9, 0.1);
    --main-yellow: #bdbb49;
    --main-yellow-rgb-015: rgba(189, 187, 73, 0.1);
    --main-red: #bd150b;
    --main-red-rgb-015: rgba(189, 21, 11, 0.1);
    --main-blue: #0076bd;
    --main-blue-rgb-015: rgba(0, 118, 189, 0.1);
  }
  
  /* Apply styles only when inside custom-page class */
  .custom-page body {
    font-family: "Baloo 2", cursive;
    font-size: 16px;
    color: #ffffff;
    text-rendering: optimizeLegibility;
    font-weight: initial;
  }
  
  .custom-page .dark {
    background: #110f16;

  }
  
  .full-page {
    background: #110f16; /* Apply the background color */
    min-height: 100vh;   /* Make it span at least the full viewport height */
  }
  
  .custom-page .light {
    background: #f3f5f7;
  }
  
  .custom-page a, .custom-page a:hover {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .custom-page #pageHeaderTitle {
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
  }
  
  /* Cards */
  .custom-page .postcard {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #ffffff;
  }
  
  .custom-page .postcard.dark {
    background-color: #18151f;

  }
  
  .custom-page .postcard.light {
    background-color: #e1e5ea;
  }
  
  .custom-page .postcard__title {
    font-size: 1.75rem;
  }
  
  .custom-page .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    position: relative;
  }
  
  .custom-page .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
    
  }
  
  .custom-page .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .custom-page .postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
  }
  
  .custom-page .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    justify-content: center;
  }
  
  .custom-page .tag__item {
    display: inline-block;
    background: rgba(83, 83, 83, 0.4);
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    user-select: none;
    transition: background-color 0.3s;
  }
  
  .custom-page .tag__item:hover {
    background: rgba(83, 83, 83, 0.8);
  }
  
  .custom-page .postcard__img_link {
    display: contents;
  }
  
  .custom-page .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
  }
  
  .custom-page .postcard:hover .postcard__bar {
    width: 100px;
  }
  
  .custom-page .postcard:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 1;
    border-radius: 10px;
  }
  
  /* Media Queries */
  @media screen and (min-width: 769px) {
    .custom-page .postcard {
      flex-wrap: inherit;
    }
  
    .custom-page .postcard__title {
      font-size: 2rem;
    }
  
    .custom-page .postcard__tagbox {
      justify-content: start;
    }
  
    .custom-page .postcard__img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }
  
    .custom-page .postcard__text {
      padding: 3rem;
      width: 100%;
    }
  
    .custom-page .media .postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }
  
    .custom-page .postcard:hover .postcard__img {
      transform: scale(1.1);
    }
  
    .custom-page .postcard:nth-child(2n+1) {
      flex-direction: row;
    }
  
    .custom-page .postcard:nth-child(2n+0) {
      flex-direction: row-reverse;
    }
  
    .custom-page .postcard:nth-child(2n+1) .postcard__text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }
  
    .custom-page .postcard:nth-child(2n+0) .postcard__text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }
  
  @media screen and (min-width: 1024px) {
    .custom-page .postcard__text {
      padding: 2rem 3.5rem;
    }
  
    .custom-page .postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      top: -20%;
      height: 130%;
      width: 55px;
    }
  
    .custom-page .postcard.dark .postcard__text:before {
      background: #18151f;
    }
  
    .custom-page .postcard.light .postcard__text:before {
      background: #e1e5ea;
    }
  }
  
  /* COLORS */
  .custom-page .postcard .postcard__tagbox .green.play:hover {
    background: var(--main-green);
    color: black;
  }
  
  .custom-page .green .postcard__title:hover {
    color: var(--main-green);
  }
  
  .custom-page .green .postcard__bar {
    background-color: var(--main-green);
  }
  
  .custom-page .green::before {
    background-image: linear-gradient(-30deg, var(--main-green-rgb-015), transparent 50%);
  }
  
  .custom-page .green:nth-child(2n)::before {
    background-image: linear-gradient(30deg, var(--main-green-rgb-015), transparent 50%);
  }
  
  .custom-page .postcard .postcard__tagbox .blue.play:hover {
    background: var(--main-blue);
  }
  
  .custom-page .blue .postcard__title:hover {
    color: var(--main-blue);
  }
  
  .custom-page .blue .postcard__bar {
    background-color: var(--main-blue);
  }
  
  .custom-page .blue::before {
    background-image: linear-gradient(-30deg, var(--main-blue-rgb-015), transparent 50%);
  }
  
  .custom-page .blue:nth-child(2n)::before {
    background-image: linear-gradient(30deg, var(--main-blue-rgb-015), transparent 50%);
  }
  
  .custom-page .postcard .postcard__tagbox .red.play:hover {
    background: var(--main-red);
  }
  
  .custom-page .red .postcard__title:hover {
    color: var(--main-red);
  }
  
  .custom-page .red .postcard__bar {
    background-color: var(--main-red);
  }
  
  .custom-page .red::before {
    background-image: linear-gradient(-30deg, var(--main-red-rgb-015), transparent 50%);
  }
  
  .custom-page .red:nth-child(2n)::before {
    background-image: linear-gradient(30deg, var(--main-red-rgb-015), transparent 50%);
  }
  
  .custom-page .postcard .postcard__tagbox .yellow.play:hover {
    background: var(--main-yellow);
    color: black;
  }
  
  .custom-page .yellow .postcard__title:hover {
    color: var(--main-yellow);
  }
  
  .custom-page .yellow .postcard__bar {
    background-color: var(--main-yellow);
  }
  
  .custom-page .yellow::before {
    background-image: linear-gradient(-30deg, var(--main-yellow-rgb-015), transparent 50%);
  }
  
  .custom-page .yellow:nth-child(2n)::before {
    background-image: linear-gradient(30deg, var(--main-yellow-rgb-015), transparent 50%);
  }
  
  @media screen and (min-width: 769px) {
    .custom-page .postcard__title {
      font-size: 2.25rem;
    }
  }
  
  .bet-input-field {
    background-color: transparent; /* Make background transparent */
    border: 1px solid #ccc; /* Grey border */
    color: inherit; /* Inherit text color from parent */
    padding: 10px;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%; /* Adjust width to fit the container */
    transition: border-color 0.3s ease; /* Smooth border transition on focus */
  }
  
  .bet-input-field:focus {
    outline: none; /* Remove default outline */
    border-color: #999; /* Darker grey border on focus */
  }
  .tag__item .active {
    color: rgb(22, 114, 252); /* Change text color to blue */
    font-weight: bold; /* Optional: Make the active item bold */
  }
    

  .bet-odds-button {
    background-color: transparent; /* Transparent background */
    color: white; /* White text */
    border: 2px solid white; /* White border */
    border-radius: 4px; /* Rounded corners */
    padding: 12px; /* Padding for better click area */
    font-size: 16px; /* Same font size as the main buttons */
    width: 100%; /* Full width of the parent container */
    text-align: center; /* Center align the text */
    cursor: pointer; /* Pointer cursor for clickable effect */
    transition: all 0.3s ease; /* Smooth hover transition */
  }
  
  .bet-odds-button:hover {
    opacity: 0.8; /* Slight transparency on hover */
  }
  
  .bet-odds-button.full-width {
    display: block; /* Ensure it behaves as a block element */
    margin: 10px 0; /* Add margin for spacing */
  }

  .confirm-bet-button {
    background-color: transparent; /* Transparent background */
    color: rgb(255, 255, 255); /* White text */
    border: 2px solid white; /* White border */
    border-radius: 4px; /* Rounded corners */
    padding: 12px; /* Padding for better click area */
    font-size: 16px; /* Font size matching other buttons */
    width: 100%; /* Full width of the parent container */
    text-align: center; /* Center align the text */
    cursor: pointer; /* Pointer cursor for clickable effect */
    transition: all 0.3s ease; /* Smooth hover transition */
  }
  
  .confirm-bet-button:hover {
    opacity: 0.8; /* Slight transparency on hover */
    background-color: green;
  }
  
  .confirm-bet-button.full-width {
    display: block; /* Block display for full-width behavior */
    margin: 10px 0; /* Margin for spacing */
  }

  .returns-card {
    background-color: transparent; /* Transparent background */
    border: 2px solid white; /* White border for card */
    border-radius: 8px; /* Rounded corners */
    color: white; /* White text */
    padding: 16px; /* Spacing inside the card */
    margin-top: 20px; /* Space between other components */
    text-align: center; /* Center-align text */
  }
  
  .returns-title {
    font-size: 18px; /* Title size */
    margin-bottom: 12px; /* Space below title */
  }
  
  .returns-list p {
    font-size: 16px; /* Text size */
    margin: 10px 0; /* Spacing between paragraphs */
  }
  
  .returns-list p:hover {
    opacity: 0.8; /* Add hover effect for clarity */
  }
  
  .returns-card:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Light hover background */
  }
  
  .team-names {
    display: flex;
    justify-content: space-between;  /* Distribute space between the team names */
    align-items: center;             /* Vertically center the team names */
    width: 100%;                     /* Make the team names container full-width */
    margin-bottom: 10px;             /* Space between team names and buttons */
  }
  
  .home-team-name,
  .draw-team-name,
  .away-team-name {
    font-size: 15px;
    font-weight: bold;
    text-align: center;              
    width: 30%;                     
  }
  
  /* Optional: Styling for better visual separation */
  .home-team-name {
    text-align: center;                /* Align home team name to the left */
  }
  
  .draw-team-name {
    text-align: center;              /* Center the draw name */
  }
  
  .away-team-name {
    text-align: center;               /* Align away team name to the right */
  }
  
  .user-bet-list ul {
    list-style-type: none; /* Remove the default bullet points */
    padding-left: 0; /* Remove any default padding on the left */
  }

  .user-bet-list {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .bet-list-container {
    max-height: 200px; /* Max height before scrolling */
    overflow-y: auto; /* Enable vertical scroll */
    scrollbar-width: thin; /* For modern browsers */
    scrollbar-color: #888 #222; /* Scrollbar styling */
    padding-right: 5px; /* Prevent content clipping near scrollbar */
  }
  
  .bet-list-container.scrollable::-webkit-scrollbar {
    width: 8px; /* Width of scrollbar for webkit-based browsers */
  }
  
  .bet-list-container.scrollable::-webkit-scrollbar-track {
    background: #222; /* Scrollbar track background */
  }
  
  .bet-list-container.scrollable::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded scrollbar thumb */
  }
  
  .bet-item {
    padding: 8px 0;
    border-bottom: 1px solid #444; /* Divider between entries */
  }
  
  .bet-item:last-child {
    border-bottom: none; /* Remove divider for last item */
  }
  
  .bet-item strong {
    color: #fff; /* Username in white */
  }
  
  .bet-amount, .bet-pick {
    color: #f39c12; /* Highlighted text in orange */
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #222;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    text-align: center;
    color: white;
  }
  
  .modal-body {
    margin-top: 15px;
  }
  
  .confirm-bet-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .close-modal-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .open-modal-button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

