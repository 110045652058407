.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #222427;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-headerV2 {
  background-color: #222427;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}
.text-orange {
  color: orange;
}

.matchweek-dropdown {
  max-height: 200px; /* Adjust max-height as needed */
  overflow-y: auto;
}

.bg-warning {
  background-color: yellow; /* Change to your desired color */
}
.highlight-row {
  background-color: rgba(255, 255, 0, 0.5) !important;
}

/* global leaderboard style   */

.custom-table {
  border-radius: 8px;
  margin-top: 20px;
}



.table tbody tr:nth-child(even) {
  background-color: #f3e5f5; /* Light purple */
}

.table tbody tr:hover {
  background-color: #e1bee7; /* Slightly darker light purple on hover */
}



.btn-purple:hover {
  background-color: #732d91; /* Darker purple on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Button shadow effect */
}





/* Styles for the current user's row background */
.current-user-row {
  background-color: #fffb01 !important; /* Light purple for entire row */
}

/* Specific style to override the text color within the row */
.current-user {
  background-color: #c79abd !important; /* Red or your preferred color for username */
  font-weight: bold; /* Keep the bold styling */
  color: #00FF00 !important;
}

/* Optional: Add extra emphasis for the current user text */
.current-user-row .current-user {
  color:  #00FF00 !important; /* Enforce the color */
  font-weight: bold;
}

.border-custom {
  position: relative;
  border: 3px solid purple; /* Solid purple border */
  z-index: 1; /* Ensure it stays above the background */
  border-radius: 8px; /* Optional: Add border-radius if needed */

}

.border-custom::before {
  content: '';
  position: absolute;
  /* top: -3px; 
  left: -3px; 
  right: -3px; 
  bottom: -3px;  */
  border-radius: 8px; /* Match the border-radius of the card */
  border: 3px solid transparent; /* Transparent border for positioning */
  background: linear-gradient(90deg, rgba(138, 14, 138, 0.8), rgba(255, 0, 255, 0.8), rgba(138, 14, 138, 0.8));
  background-size: 200% 200%; /* Double the size for animation */
  z-index: -1; /* Behind the card content */
  animation: rotateBorder 5s linear infinite, twinkleBorder 2s ease-in-out infinite; /* Combined animations */
}

@keyframes rotateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}








.btn-purple-leaderboard {
  background-color: rgb(138, 14, 138) !important;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
}

  /* global leaderboard style   */


.matchweek-dropdown::-webkit-scrollbar {
  width: 5px;
}

.matchweek-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.matchweek-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.matchweek-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Instagram CSS Linear Gradient Background */
.gradient {
  background: #833ab4;
  background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
}

.instagram-icon {
  width: 23px;
  height: 23px;
  fill: url(#instagramGradient);
}
.bg-warning {
  background-color: #ffc107; /* Example background color */
  font-weight: bold; /* Example font-weight */
}


.highlight-row {
  background-color: #ffffcc; /* Example highlight color */
  font-weight: bold; /* Example: make the text bold */
}

.bg-dashed-orange-black {
  background: repeating-linear-gradient(
    45deg,
    #F94D00,        /* Tangelo color */
    #F94D00 10px,   /* End color and width of the first stripe */
    black 10px,     /* Start color of the second stripe */
    black 20px      /* End color and width of the second stripe */
  );
  color: white;     /* Text color */
}
.text-bg-babyblue {
  background-color: rgb(105, 192, 241);
}

.App-headerV3 {
background-color: #f0f2f5;
  min-height: 90vh;
}
.App-headerV8 {
  background-color: #f0f2f5;
  min-height: 90vh;
}
.Nav-headerV8 {
  background-color: #fff;
}

.App-headerV4 {
  background-color: #222427;
  min-height: 90vh;
padding-top: 5%;
}
.App-headerV5 {
  min-height: 90vh;
  background-color: #f8f9fa;
}
.App-headerV6 {
  min-height: 100vh;
  background-color: #222427;
}
.App-headerV7 {
  min-height: 100vh;
  background-color: #f8f9fa;
}
.App-headerV8 {
  background-image: url('./Predictify-2.png'); /* Use the correct path */
  background-size: cover;        /* Fill the screen */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;  /* Keeps it in place when scrolling */
  min-height: 100vh;
}

/* For mobile-specific adjustments */
@media (max-width: 768px) { /* Adjust for smaller screens */
  .App-headerV8 {
    background-size: cover; /* Ensure it continues to cover full viewport */
    background-position: left;      /* Focus on the left for mobile */

  }
}

.home-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.match-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.match-date {
  font-size: 16px;
  margin-bottom: 20px;
}

.betting-ui {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.odds-section {
  margin-bottom: 15px;
}

.odds-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.odds p {
  margin: 5px 0;
  font-size: 16px;
}

.bet-input {
  margin-bottom: 15px;
}

.bet-input input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.potential-returns {
  background-color: #e9ffe9;
  padding: 10px;
  border-radius: 5px;
}

.returns-list p {
  font-size: 16px;
  margin: 5px 0;
}

.bet-buttons {
  display: flex;
  justify-content: space-between;
}

.bet-button {
  padding: 12px;
  font-size: 16px;
  width: 30%;
  cursor: pointer;
  border: 2px solid transparent; /* Set initial border to transparent */
  border-radius: 4px;
  color: white; /* Set text color to white */
  background-color: transparent; /* Make background transparent */
  border-color: white;
  transition: all 0.3s ease; /* Smooth transition for hover and active states */
}

.bet-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight background change on hover */
  opacity: 0.9; /* Add a bit of opacity change for visual feedback */
}

.bet-button.active {
  border-color: rgb(27, 134, 255); /* Show white border when active */
  background-color: rgba(255, 255, 255, 0.2); /* Light background when active */
}



.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}

.prize-pot {
  font-size: 1.2em;
  font-weight: bold;
  color: #28a745;
  margin-top: 20px;
  text-align: center;
}




.bet-item {
  margin-bottom: 10px;
}

.bet-item strong {
  color: #007bff;
}



/* .App-headerV8 {
  background-image: url('./Predictify-2.png');
  background-size: cover;        
  background-position: center;   
  background-repeat: no-repeat;  
  min-height: 100vh;
} */

.mb-3 .form-control:focus {
  box-shadow: 0 0 1px #13a863; 
}

.form-select:focus {
  box-shadow: 0 0 1px #13a863; 

}
.bg-orange { 
  background-color: rgb(255, 72, 5);
}
.table-purple { 
  background-color: #833ab4 !important;
}

.table-striped-primary tbody tr:nth-of-type(odd) {
  background-color: #dd2697; /* Bootstrap primary color or your defined primary */
  color: white;
}

/* Apply transparent background to the entire table */
.transparent-table {
  background-color: transparent !important;
}

.transparent-table th,
.transparent-table td {
  background-color: transparent !important;
}

/* Remove Bootstrap striping by setting even and odd rows to transparent */
.transparent-table tbody tr:nth-child(even) {
  background-color: transparent !important;
}

.transparent-table tbody tr:nth-child(odd) {
  background-color: transparent !important;
}

.btn-current-round {
  border: 2px solid blue; /* Adjust the border size and color as needed */
}



/* Default style for the team column on smaller screens */
.team-column {
  width: 40%; /* width for smaller screens */
}
.table-striped-custom {
  color: #732d91;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .team-column {
    width: 15%; /* width for larger screens */
  }
}


/*EURO 2024 standings*/

.container-1 {
  display: flex;
  justify-content: center;
}






.grid {
  display: grid;
  grid-template-columns: repeat(4, 205px);
  align-items: center;
}

.justify-space-around {
  display: grid;
  align-content: space-around;
  min-height: 100%;
}

.round {
  position: relative;
  max-width: 185px;
}

.match {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 80px;
  width: 180px;
  margin: 10px auto;
  background-color: white;
  border: 1px solid black;
}

.link {
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 100%;
  right: -10px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.link:after {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid red;
}

/*EURO 2024 standings*/
/*bracket css*/

/*bracket css*/

.padtopinput {
  padding-top: 25px;
}

.input-error {
  border-color: rgb(112, 112, 112);
}
.active-button {
  background-color: black;
  color: white;
}
.text-light-hover {
  color: white;
}
.text-light-hover:hover {
  color: #222427;
}

.form-control:focus {
  box-shadow: 0 0 2px  #13a863;

}
.form-check-input:focus {
  box-shadow: 0 0 1px #13a863; /* Customize the box shadow for focus state */
}

.accordion-button:focus {
  box-shadow: 0 0 3px #13a863; /* Customize the box shadow for focus state */
}
.dropdown-menu.custom {
  max-height: 200px; /* Set a maximum height for the dropdown menu */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-menu-inner {
  overflow-y: auto; /* Enable vertical scrolling for inner content */
}


.mb-3 {
  border-radius: 10px
}
.dark {
  background-color: #222427;
}
.App-link {
  color: #61dafb;
}
.btn-info{
  margin-top: 0.5rem
}

.form-control me-2{
  box-shadow: 0 0 1px #13a863; 

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.default-profile-picture {
  width: 100px; 
  height: 100px; 
  border-radius: 50%; 
  object-fit: cover; 
}

.avatar{
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-width: 3px;
  border-color: #3b5781;
  border-style: outset;
}
.padtop{
  padding-top: 5px
}
.padbot{
  padding-bottom: 5px
}
.padtop1 {
  padding-top: 10px;
}
.padtopbot {
  padding-bottom: 10px;
  padding-top: 10px
}

.form-control {
  border-color: #e6eeff;
}
.custom.show a:hover {
  background-color:  rgb(138, 14, 138) !important;
  color: #fff;

}

.text-purple {
  color:  rgb(138, 14, 138) !important;
}
.border-purple{
  color:  rgb(138, 14, 138) !important;
  color: #8a0ea0;

}
.homehover:hover {
  text-decoration: underline;}

.bg-outline-purple {
  border-color:  rgb(138, 14, 138) !important;

}

.navhover:hover{
  color:  rgb(138, 14, 138) !important;

}

.custom-bg-purple {
  color: black; /* Ensure text is readable on the purple background */
}

.custom-bg-purple:hover,
.custom-bg-purple:focus,
.custom-bg-purple:active {
  background-color: rgb(138, 14, 138) !important;
  color: #fff;
}


.btn-custom,
.btn-custom:hover {
  background-color: rgb(138, 14, 138) !important;
  color: #fff;
}
.rotate-on-hover:hover {
  transform: rotateY(180deg); /* Rotate horizontally (left-to-right) */
}
.Arsenal {
  transition: transform 0.7s ease; /* Add a transition for the transform property with a duration of 0.3 seconds and easing */
}
.placeholder-white::placeholder {
  color: white;
}

.Arsenal:hover {
  /* transform: scale(1.14);  */
  transform: rotateY(180deg); 
}

.hover:hover {
  border-color: #e01414; /* Change to the desired background color */
}

/* Define the colors for Aston Villa Home Kit */
.astonvillakithome .card-header {
  background-color: rgb(127, 0, 0); /* Claret (Deep Red) */
  color: white; /* Text color */
}

.text-bg-astonvillakithome  {
  background-color: rgb(127, 0, 0); /* Claret (Deep Red) */
  color: white; /* Text color */
}
.bournemouthkithome .card-header {
  background: red;
    color: rgb(0, 0, 0); /* Text color */
  }
  .bournemouthkitaway .card-header {
    background: rgb(83, 193, 226);
      color: rgb(255, 255, 255); /* Text color */
    }
    .chelseakitaway .card-header{
      background: #044099;
      color: rgb(255, 255, 255); /* Text color */

    }
    .crystalpalacehomekit .card-header {
      background: linear-gradient(to right, red 50%, blue 50%);
      color: white; /* Text color */
    }
    .FulhamKitAway .card-header {
      background: hotpink;
      color: white; /* Text color */
    }
.text-bg-lutonhomekit{
  background: orangered;
  color: white;
}    
.mancitykithome .card-header {
  background: lightblue;
  color: white;
}
.manunitedawaykit .card-header {
  background: darkgreen;
  color: white;
}
.tottenhamkitaway .card-header {
  background: darkblue;
  color: white;
}

.Liverpoolawaykit .card-header {
  background: lightgreen;
  color: rgb(0, 0, 0);
}
.padtopstats {
  padding-top: 25px;
}
.purple-bg {
  background-color: rgb(138, 14, 138) !important;
}
.border-purple {
  color: rgb(138, 14, 138) !important;
}
.btn-purple {
  color: white;
  background-color: rgb(138, 14, 138) !important;
}
.purple1 {
  color: rgb(218, 24, 218) !important;

}
.btn-purple:hover {
  color: white; /* Maintain white text color on hover */
  background-color: rgb(138, 14, 138) !important;
}
.btn-outline-purple {
  color: white;
  background-color: rgb(138, 14, 138)!important;
}



.rounds-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 85%;
  scroll-behavior: smooth; /* Keep smooth scrolling */
  
  /* Hide scrollbar on different browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.rounds-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.sticky-sidebar, .sticky-card {
  position: sticky;
  top: 0;  /* Sticks the elements at the top of the viewport */
}

.scrollable-cards {
  overflow-y: auto;  /* Allows the main content to scroll */
  max-height: 100vh;  /* Ensure it scrolls within the viewport */
}

.scrollable-cards {
  height: 100vh; /* Allow the content to take up the full viewport height */
  overflow-y: scroll; /* Enables scrolling functionality */
  -ms-overflow-style: none;  /* Hides scrollbar in IE/Edge */
  scrollbar-width: none;  /* Hides scrollbar in Firefox */
}

.potential-returns {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 15px;
}

.returns-breakdown {
  display: flex;
  flex-direction: column;
}

.return-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
}

.return-row:last-child {
  border-bottom: none;
}

.return-row span {
  color: #666;
}

.return-row strong {
  color: #333;
}

.sidebar {
  position: fixed;
  top: 100px; /* Adjust this value to position it vertically */
  left: 20px; /* Adjust this value to control horizontal positioning */
  width: calc(16.666% - 20px); /* Maintain the original column width */
  height: calc(100vh - 150px); /* Optional: limit height and make scrollable */
  overflow-y: auto; /* Optional: add scrolling if content is long */
  z-index: 10; /* Ensure it stays on top of other content */
}

.fixed-bet-card {
  position: fixed;
  top: 100px; /* Adjust this value to position it vertically */
  right: 20px; /* Adjust this value to control horizontal positioning */
  width: calc(25% - 20px); /* Maintain the original column width */
  z-index: 10; /* Ensure it stays on top of other content */
}


/* .nav-link.active {
  border-bottom: 0.5px solid blue; 
} */

/* @media (max-width: 767px) {
  .col.text-end {
    display: grid;
  }

  .col.text-end img {
    grid-row: 1;
    justify-self: center;
  }

  .col.text-end span {
    grid-row: 2;
  }
} */

/* @media (min-width: 778px) {
  .card:hover{
    transform: scale(1.005); 

  }
} */

